/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AnimatePresence } from 'framer-motion';
import React, { ComponentPropsWithRef, FC, useMemo, useState } from 'react';
import { usePlcApiCall } from '@api/business/api.hook';
import { FadeInElement } from '@components/animations/FadeInElement/FadeInElement.component';
import { PrimaryButton } from '@components/buttons/FlatButton/FlatButton.component';
import { IconSizes } from '@components/icons/icon.types';
import { WeatherCodeIcon } from '@components/icons/meteo/WeatherCode';
import { PlcFlatRectLoader } from '@components/Loaders/PlcFlatRectLoader.component';
import { PlcTextListLoader } from '@components/Loaders/PlcTextLoader.component';
import { PlcMessageWithRetry } from '@components/PlcMessage/PlcMessageWithRetry.component';
import { Text } from '@components/Text/Text.component';
import { PlcAnchor } from '@layout/components/PlcAnchor/PlcAnchor.component';
import { useTranslation } from '@locales/useTranslation.hook';
import { useLocation } from '@meteo/business/LocationSelector.hook';
import { computeMeteoInfoFromDateAndPostion, getLastCity, isItNight } from '@meteo/business/meteo.utils';
import { mapApiWeatherCodeToWeatherCode } from '@meteo/business/meteo.weatherCodeMapping';
import { buildWeatherLink } from '@meteo/business/meteoUrl.utils';
import {
  MeteoCityAutocomplete,
  MeteoCityAutocompleteLoader,
} from '@meteo/components/MeteoCityAutocomplete/MeteoCityAutocomplete.component';
import { MeteoSingleMetric } from '@meteo/components/MeteoSingleMetric/MeteoSingleMetric.component';
import { MeteoMetric } from '@meteo/types';
import { Breakpoint, useIsBreakpointDown } from '@styles/breakpoints';
import { formatDateToDateTime, formatDateToTime, plcDayjs } from '@utils/date';
import { fetchSummaryForecasts} from '@utils/fetch';
import { formatNumberWithComma } from '@utils/number';
import { isServer } from '@utils/server';
import { urlEnum } from '@utils/url';
import classnames from 'classnames';

const JournalWeather: FC<ComponentPropsWithRef<'div'>> = () => {
  const { t } = useTranslation(['common', 'meteo']);

  const [city, setCity] = useState(getLastCity());
  const { coordinates, isGeolocationLoading } = useLocation(city);

  const {
    data: forecast,
    isValidating,
    error: forecastError,
    retryCallback: retryForecast,
  } = usePlcApiCall(fetchSummaryForecasts, [coordinates], { enabled: Boolean(coordinates) });

  const isLoading = isGeolocationLoading || isValidating;

  const isMobileOrTablet = useIsBreakpointDown(Breakpoint.s);
  const isLoadingOrServer = isLoading || isServer();

  const dateTime = forecast ? forecast.weatherCode.date : new Date();
  const updateString = useMemo(() => {
    const dateTimeWithOffset = plcDayjs(dateTime).subtract(1, 'hour');
    return dateTimeWithOffset.isSame(plcDayjs(), 'day')
      ? t('common.last-update-time', { time: formatDateToTime(dateTimeWithOffset) })
      : formatDateToDateTime(dateTimeWithOffset);
  }, [dateTime]);

  const additionalInfos = coordinates
    ? computeMeteoInfoFromDateAndPostion(new Date(), coordinates.latitude, coordinates.longitude)
    : undefined;

  const metrics =
    isLoadingOrServer || forecast ? (
      <div className="metrics flex space-around flex-start-up-s">
        <MeteoSingleMetric
          isLoading={isLoadingOrServer}
          isSmall
          type={MeteoMetric.RainLevel}
          value={t('meteo.rain-level', {
            value: forecast ? formatNumberWithComma(forecast.precipitationAmount.value, 1) : 0,
          })}
        />
        <MeteoSingleMetric
          isLoading={isLoadingOrServer}
          isSmall
          type={MeteoMetric.MinMaxTemperature}
          value={t('meteo.temp-min-max', {
            max: forecast ? formatNumberWithComma(forecast.maxAirTemperature.value) : 0,
            min: forecast ? formatNumberWithComma(forecast.minAirTemperature.value) : 0,
          })}
        />
        <MeteoSingleMetric
          isLoading={isLoadingOrServer}
          isSmall
          type={MeteoMetric.WindSpeed}
          value={t('meteo.wind-speed', {
            value: forecast ? formatNumberWithComma(forecast.windSpeedAt2m.value) : 0,
          })}
        />
        <MeteoSingleMetric
          isLoading={isLoadingOrServer}
          isSmall
          options={{ angle: forecast ? forecast.windDirection.value.degree : 0 }}
          type={MeteoMetric.WindDirection}
          value={forecast ? forecast.windDirection.value.cardinal : ''}
        />
      </div>
    ) : null;

  return (
    <div className="content-wrapper">
      <div className={classnames('content', isLoadingOrServer)}>
        <AnimatePresence initial={false}>
          <FadeInElement key="loader" animateHeight={false} show={isLoadingOrServer}>
            <div className="slide-summary">
              <div className="slide-summary-title">
                <PlcTextListLoader
                  className="weather-code-title"
                  fixedWidth
                  textProps={[{ numberOfLine: 2, variant: isMobileOrTablet ? 'h3' : 'h2', width: 300 }]}
                  uniqueKey="weather-code"
                />
              </div>
            </div>
            <MeteoCityAutocompleteLoader className="plc-mb-basis" />
            {metrics}
            <PlcFlatRectLoader fixedWidth height={30} uniqueKey="weather-flat-rect" width={235} />
          </FadeInElement>
          <FadeInElement key="main" animateHeight={false} show={!isLoadingOrServer}>
            <div className="slide-summary">
              {forecastError ? (
                <div className="slide-summary-title">
                  <PlcMessageWithRetry retryCallback={retryForecast} />
                </div>
              ) : forecast ? (
                <>
                  <div className="slide-summary-title">
                    <Text
                      className="weather-code-title"
                      i18nKey={`meteo.weather-code.${mapApiWeatherCodeToWeatherCode(forecast.weatherCode.value)}`}
                      tag="p"
                      variant={isMobileOrTablet ? 'h3' : 'h2'}
                    />
                    <Text flavour="grey" tag="p" variant={isMobileOrTablet ? 'h6' : 'h5'}>
                      {updateString}
                    </Text>
                  </div>
                  <div className="temperature-weather-code-line">
                    <Text
                      i18nKey="meteo.temperature"
                      i18nOptions={{ value: formatNumberWithComma(forecast.airTemperature.value!, 1) }}
                      tag="span"
                      variant={isMobileOrTablet ? 'h2' : 'h1'}
                    />
                    {!isServer() && additionalInfos && (
                      <div className="weather-code-icon">
                        <WeatherCodeIcon
                          height={isMobileOrTablet ? 85 : 118}
                          night={isItNight(additionalInfos.sunrise, additionalInfos.sunset)}
                          variant={IconSizes.xl}
                          weatherCode={forecast.weatherCode.value!}
                          width={isMobileOrTablet ? 180 : 300}
                        />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="slide-summary-title">
                    <Text className="h2-up-s" i18nKey="journal.weather.no-locality.title" tag="h2" variant="h3" />
                  </div>
                  <div className="weather-code-icon">
                    <WeatherCodeIcon
                      height={isMobileOrTablet ? 85 : 118}
                      variant={IconSizes.xl}
                      weatherCode={2}
                      width={isMobileOrTablet ? 180 : 300}
                    />
                  </div>
                </>
              )}
            </div>
            <MeteoCityAutocomplete
              className="plc-mb-basis flex-1"
              current={city}
              inputId=""
              onSelect={c => (c && setCity ? setCity(c) : undefined)}
              withConnectSuggestion={!forecast}
              useLastCity={true}
            />
            {metrics}
            {forecast ? (
              <PlcAnchor linkProps={buildWeatherLink(urlEnum.meteo, {}, city)}>
                <PrimaryButton
                  textProps={{ i18nKey: 'meteo.go-to', variant: isMobileOrTablet ? 'small' : undefined }}
                />
              </PlcAnchor>
            ) : (
              <Text
                className="slide-description"
                i18nKey="journal.weather.no-locality.description"
                tag="p"
                variant="small"
              />
            )}
          </FadeInElement>
        </AnimatePresence>
      </div>
    </div>
  );
};

export { JournalWeather };
