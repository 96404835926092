import { SkyCode } from 'pleinchamp-api-client';
import { WeatherCode } from '@meteo/types';

const WeatherCodeMap = new Map([
  [1, WeatherCode.ClearSkies],
  [101, WeatherCode.ClearSkies],
  [2, WeatherCode.PartlyCloudy],
  [102, WeatherCode.PartlyCloudy],
  [3, WeatherCode.MainlyCloudy],
  [103, WeatherCode.MainlyCloudy],
  [4, WeatherCode.Overcast],
  [104, WeatherCode.Overcast],
  [5, WeatherCode.Rain],
  [105, WeatherCode.Rain],
  [6, WeatherCode.RainAndSnow],
  [106, WeatherCode.RainAndSnow],
  [7, WeatherCode.Snow],
  [107, WeatherCode.Snow],
  [8, WeatherCode.RainShower],
  [108, WeatherCode.RainShower],
  [9, WeatherCode.SnowShower],
  [109, WeatherCode.SnowShower],
  [10, WeatherCode.RainAndSnowShower],
  [110, WeatherCode.RainAndSnowShower],
  [11, WeatherCode.Mist],
  [111, WeatherCode.Mist],
  [12, WeatherCode.Mist],
  [112, WeatherCode.Mist],
  [13, WeatherCode.FreezingRain],
  [113, WeatherCode.FreezingRain],
  [14, WeatherCode.Thunderstorms],
  [114, WeatherCode.Thunderstorms],
  [15, WeatherCode.LightDrizzle],
  [115, WeatherCode.LightDrizzle],
  [16, WeatherCode.Sandstorm],
  [116, WeatherCode.Sandstorm],
]);

export function mapApiWeatherCodeToWeatherCode(wc?: number): WeatherCode {
  if (wc === undefined || wc === null) {
    return WeatherCode.ClearSkies;
  }

  const weatherIconCode: WeatherCode | undefined = WeatherCodeMap.get(wc);
  return weatherIconCode ? weatherIconCode : WeatherCode.ClearSkies;
}

export function mapSkyCodeToWeatherCode(skyCode: SkyCode): WeatherCode {
  switch (skyCode) {
    case SkyCode.Clear:
      return WeatherCode.ClearSkies;
    case SkyCode.PartlyCloudy:
      return WeatherCode.PartlyCloudy;
    case SkyCode.Overcast:
      return WeatherCode.Overcast;
    case SkyCode.Mist:
      return WeatherCode.Mist;
    case SkyCode.Rain:
      return WeatherCode.Rain;
    case SkyCode.Snowy:
      return WeatherCode.Snow;
    case SkyCode.Storm:
      return WeatherCode.Thunderstorms;
    default:
      throw new Error(`Unknow sky code ${skyCode}`);
  }
}
